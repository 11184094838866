<app-header
  [navbarBrandRouterLink]="['/']"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/Roche-Logo.png', width: 80, alt: 'Lesson Learned Management System'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/Roche-Logo.png', width: 80, alt: 'Lesson Learned Management System'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false">
  <!-- <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Dashboard</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Users</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Settings</a>
    </li>
  </ul> -->
  <ul class="nav navbar-nav ml-auto">    
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <img src="{{avatarImg}}" onerror="this.src='assets/img/avatars/user.png';" class="rounded-circle" alt="Avatar" width="30" height="30"> 
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header "><i class="icon-user"></i> &nbsp;&nbsp; <strong>{{loginuser}}</strong></div>
        <!-- 
        <a class="dropdown-item" href="#"><i class="fa fa-bell-o"></i> Updates<span class="badge badge-info">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span class="badge badge-success">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span class="badge badge-danger">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span class="badge badge-warning">42</span></a>
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" href="#"><i class="fa fa-wrench"></i> Setting</a>
        <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-dark">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span class="badge badge-primary">42</span></a>
        <div class="divider"></div>
        <a class="dropdown-item" href="#"><i class="fa fa-shield"></i> Lock account</a> -->
        <a class="dropdown-item" href="#" (click)="logout()" ><i class="fa fa-lock" style="padding-left: 7%;"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb>
 
    </cui-breadcrumb>
    <!-- deprecation warning for 'app-breadcrumb' -->
    <!--<ol class="breadcrumb">-->
      <!--<app-breadcrumb></app-breadcrumb>-->
      <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
      <!--<li class="breadcrumb-menu d-md-down-none">-->
        <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
          <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
          <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
          <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
        <!--</div>-->
      <!--</li>-->
    <!--</ol>-->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
   
</div>
<app-footer>  
  <span class="ml-auto">Powered by <a href="https://ignatiuz.com">Ignatiuz</a></span>
</app-footer>
