<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body ">
                <div>
                  <h2>Lighthouse Tool</h2>
                  <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100" class="progress-bar" role="progressbar"
                    style="width:100%;height: 1px; background: #fff;"></div>
                  <div>
                    <i class="cui-circle-check icons font-1xl d-block mt-2"> <span> Data management. </span> </i>
                    <i class="cui-circle-check icons font-1xl d-block mt-2"> <span> Lesson categorizing. </span> </i>
                    <i class="cui-circle-check icons font-1xl d-block mt-2"> <span> Review process control. </span> </i>
                    <i class="cui-circle-check icons font-1xl d-block mt-2"> <span> Knowledge repository. </span> </i>
                    <i class="cui-circle-check icons font-1xl d-block mt-2"> <span> Reports. </span> </i>
                  </div>
                </div>
              </div>
            </div>
            <div class="card p-4 justify-content-center">
              <!-- <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input type="text"
                      [ngClass]="{'has-error is-invalid': isSubmitted && formControls.username.errors }"
                      formControlName="username" placeholder="Username" autocomplete="username" required
                      class="form-control">
                    <div *ngIf="isSubmitted && formControls.username.errors" class="invalid-feedback"
                      style="text-align: right">Please enter username...</div>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" class="form-control"
                      [ngClass]="{ 'has-error is-invalid': isSubmitted && formControls.password.errors }"
                      formControlName="password" autocomplete="current-password" placeholder="Password" required>
                    <div *ngIf="isSubmitted && formControls.password.errors" class="invalid-feedback"
                      style="text-align: right">Please enter password...</div>
                  </div>

                  <small style="color:red;position: relative;top:-20px">{{message}}</small>

                  <div class="row">
                    <div class="col-6 text-left">
                      <button type="button" class="btn btn-link px-0">Forgot password?</button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="submit" class="btn btn-primary px-4 btn-square">Login</button>
                    </div>
                  </div>
                </form>
              </div> -->

              <div class="row justify-content-center">
                <a href="#" class="google-btn" (click)="googleLogin()">
                  <div class="google-icon-wrapper">
                    <!-- <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/> -->
                    <img class="google-icon" src="./../../../assets/google.svg"/>
                  </div>
                  <p class="btn-text"><b>Sign in with google</b></p>
                </a>

                <!-- <div class="col-md-12"> <a class="btn btn-lg btn-google btn-block text-uppercase btn-outline"
                    (click)="googleLogin()"><img src="https://img.icons8.com/color/16/000000/google-logo.png"> Signup
                    Using
                    Google</a> </div>-->
              </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
  </main>
  <div class="login-overlay"></div>
</div>