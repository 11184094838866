interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  roleId?:number;  
  data?:NavData[];
}

export const navItems: NavData[] = [
  { 
    roleId: 0,
    data: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        badge: {
          variant: 'info',
          text: 'NEW'
        }
      },
      {
        title: true,
        name: 'Theme'
      },
      {
        name: 'Colors',
        url: '/theme/colors',
        icon: 'icon-drop'
      },
      {
        name: 'Typography',
        url: '/theme/typography',
        icon: 'icon-pencil'
      },
      {
        title: true,
        name: 'Components'
      },
      {
        name: 'Base',
        url: '/base',
        icon: 'icon-puzzle',
        children: [
          {
            name: 'Cards',
            url: '/base/cards',
            icon: 'icon-puzzle'
          },
          {
            name: 'Carousels',
            url: '/base/carousels',
            icon: 'icon-puzzle'
          },
          {
            name: 'Collapses',
            url: '/base/collapses',
            icon: 'icon-puzzle'
          },
          {
            name: 'Forms',
            url: '/base/forms',
            icon: 'icon-puzzle'
          },
          {
            name: 'Pagination',
            url: '/base/paginations',
            icon: 'icon-puzzle'
          },
          {
            name: 'Popovers',
            url: '/base/popovers',
            icon: 'icon-puzzle'
          },
          {
            name: 'Progress',
            url: '/base/progress',
            icon: 'icon-puzzle'
          },
          {
            name: 'Switches',
            url: '/base/switches',
            icon: 'icon-puzzle'
          },
          {
            name: 'Tables',
            url: '/base/tables',
            icon: 'icon-puzzle'
          },
          {
            name: 'Tabs',
            url: '/base/tabs',
            icon: 'icon-puzzle'
          },
          {
            name: 'Tooltips',
            url: '/base/tooltips',
            icon: 'icon-puzzle'
          }
        ]
      },
      {
        name: 'lesson',
        url: '/lesson',
        icon: 'icon-cursor',
        children: [
          {
            name: 'lesson',
            url: '/lesson',
            icon: 'icon-cursor'
          },
          {
            name: 'Dropdowns',
            url: '/buttons/dropdowns',
            icon: 'icon-cursor'
          },
          {
            name: 'Brand Buttons',
            url: '/buttons/brand-buttons',
            icon: 'icon-cursor'
          }
        ]
      },
      {
        name: 'Charts',
        url: '/charts',
        icon: 'icon-pie-chart'
      },
      {
        name: 'Icons',
        url: '/icons',
        icon: 'icon-star',
        children: [
          {
            name: 'CoreUI Icons',
            url: '/icons/coreui-icons',
            icon: 'icon-star',
            badge: {
              variant: 'success',
              text: 'NEW'
            }
          },
          {
            name: 'Flags',
            url: '/icons/flags',
            icon: 'icon-star'
          },
          {
            name: 'Font Awesome',
            url: '/icons/font-awesome',
            icon: 'icon-star',
            badge: {
              variant: 'secondary',
              text: '4.7'
            }
          },
          {
            name: 'Simple Line Icons',
            url: '/icons/simple-line-icons',
            icon: 'icon-star'
          }
        ]
      },
      {
        name: 'Notifications',
        url: '/notifications',
        icon: 'icon-bell',
        children: [
          {
            name: 'Alerts',
            url: '/notifications/alerts',
            icon: 'icon-bell'
          },
          {
            name: 'Badges',
            url: '/notifications/badges',
            icon: 'icon-bell'
          },
          {
            name: 'Modals',
            url: '/notifications/modals',
            icon: 'icon-bell'
          }
        ]
      },
      {
        name: 'Widgets',
        url: '/widgets',
        icon: 'icon-calculator',
        badge: {
          variant: 'info',
          text: 'NEW'
        }
      },
      {
        divider: true
      },
      {
        title: true,
        name: 'Extras',
      },
      {
        name: 'Pages',
        url: '/pages',
        icon: 'icon-star',
        children: [
          {
            name: 'Login',
            url: '/login',
            icon: 'icon-star'
          },
          {
            name: 'Register',
            url: '/register',
            icon: 'icon-star'
          },
          {
            name: 'Error 404',
            url: '/404',
            icon: 'icon-star'
          },
          {
            name: 'Error 500',
            url: '/500',
            icon: 'icon-star'
          }
        ]
      },
      {
        name: 'Disabled',
        url: '/dashboard',
        icon: 'icon-ban',
        badge: {
          variant: 'secondary',
          text: 'NEW'
        },
        attributes: { disabled: true },
      }
    ] 
  },
  { 
    roleId: 1,
    data: [
      {
        name: 'Dashboard',
        url: '/admin',
        icon: 'icon-speedometer'
      },
      
       
        
          {
            name: 'View Lessons',
            url: '/user/all-lessons',
            icon: 'icon-list'
          },
          {
            name: 'Record Lesson',
            url: '/user/add-project',
            icon: 'icon-plus'
          },        
        
      
      // {
      //   name: 'Profile Management',
      //   url: '/admin/lessons4',
      //   icon: 'icon-user'
      // },
      {
        name: 'User Management',
        url: '/user-management',
        icon: 'icon-people'
      },
      {
        name: 'Notification',
        url: '/notification',
        icon: 'icon-bell'
      },
      {
        name: 'Master Lists',
        url: '/masterlists',
        icon: 'icon-notebook'
      },
      {
        name: 'How Do I',
        url: '/howdoi',
        icon: 'icon-bulb'
      },
      // {
      //   name: 'System Settings',
      //   url: '/buttons1',
      //   icon: 'icon-settings'
      // }
    ] 
  },
  { 
    roleId: 2,
    data: [
      {
        name: 'Dashboard',
        url: '/pm',
        icon: 'icon-speedometer'
      },
     
      {
        name: 'View Lessons',
        url: '/user/all-lessons',
        icon: 'icon-list'
      },
      {
        name: 'Record Lesson',
        url: '/user/add-project',
        icon: 'icon-plus'
      },
          

      // {
      //   name: 'Profile management',
      //   url: '/user/lessons1',
      //   icon: 'icon-user'
      // },
      // {
      //   name: 'User Management',
      //   url: '/user-management',
      //   icon: 'icon-people'
      // },
      {
        name: 'Notification',
        url: '/notification',
        icon: 'icon-bell'
      },
      {
        name: 'Master Lists',
        url: '/masterlists',
        icon: 'icon-notebook'
      },
      {
        name: 'How Do I',
        url: '/howdoi',
        icon: 'icon-bulb'
      }
      // {
      //   name: 'System Settings',
      //   url: '/admin/lessons',
      //   icon: 'icon-settings'
      // }   
    ] 
  },
  { 
    roleId: 3,
    data: [
      {
        name: 'Dashboard',
        url: '/user',
        icon: 'icon-speedometer'
      }, 
      {
        name: 'View Lessons',
        url: '/user/all-lessons',
        icon: 'cui-lightbulb'
      },
      // {
      //   name: 'Profile management',
      //   url: '/user/lessons1',
      //   icon: 'icon-user'
      // },
      {
        name: 'Notification',
        url: '/notification',
        icon: 'icon-bell'
      },
      {
        name: 'How Do I',
        url: '/howdoi',
        icon: 'icon-bulb'
      }
    ] 
  } 
];
