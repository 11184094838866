<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h4 class="pt-3">Site is under construction</h4>
          <br>
          <img src="https://hep.um.edu.my/UMUM/webPageConstruction.jpg" alt="Error Image" width="500" height="333">
          <br>
          <br>
          <br>
          <p class="text-muted">The page you are looking for is under construction please click on Dashboard button</p>
        </div>
        <br>
        <a href="https://lighthouse.ignatiuz.com/#/login" class="btn btn-primary">Dashboard</a>
        <div class="input-prepend input-group">
          <div class="input-group-prepend">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
